import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2229fb18"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "row row1"
};
const _hoisted_2 = {
  class: "marquee"
};
const _hoisted_3 = {
  class: "inner"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "row row2"
};
const _hoisted_9 = {
  class: "marquee"
};
const _hoisted_10 = {
  class: "inner"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = ["src"];
const _hoisted_13 = ["onClick"];
const _hoisted_14 = ["src"];
const _hoisted_15 = {
  class: "row row3"
};
const _hoisted_16 = {
  class: "marquee"
};
const _hoisted_17 = {
  class: "inner"
};
const _hoisted_18 = ["onClick"];
const _hoisted_19 = ["src"];
const _hoisted_20 = ["onClick"];
const _hoisted_21 = ["src"];
import { reactive, ref } from "vue";
import { getBanner } from "@/api/Index";
import { thumb } from "@/utils/index";
import { useRouter } from "vue-router";
export default {
  __name: 'BannerSwiper',
  setup(__props) {
    function getWindowHeight() {
      let windowHeight = 0;
      if (document.compatMode === "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }
    let windowsHeight = getWindowHeight();
    let homeOpacity = ref(1);
    window.addEventListener("scroll", function () {
      let opacity = Math.floor((windowsHeight - window.document.documentElement.scrollTop) / 100) / 10;
      homeOpacity.value = opacity > 0 ? opacity : 0;
    });
    // 分割数据
    const isPaused = ref(false);
    const list1 = reactive([]);
    const list2 = reactive([]);
    const list3 = reactive([]);
    function pauseMoving() {
      console.log("pause");
      isPaused.value = true;
    }
    function playMoving() {
      isPaused.value = false;
    }
    getBanner().then(res => {
      if (res.code == 200) {
        res.data.list.forEach((ele, index) => {
          let i = index % 3;
          switch (i) {
            case 0:
              list1.push(ele);
              break;
            case 1:
              list2.push(ele);
              break;
            case 2:
              list3.push(ele);
              break;
            default:
              break;
          }
        });
      }
    });
    const router = useRouter();
    const goDetail = item => {
      router.push({
        name: "CaseDetail",
        params: {
          id: item.id
        }
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["primary", {
          paused: isPaused.value
        }]),
        style: _normalizeStyle('opacity: ' + _unref(homeOpacity))
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list1, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.id,
          onMouseenter: pauseMoving,
          onMouseout: playMoving,
          onClick: $event => goDetail(item)
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item.thumb)
        }, null, 8, _hoisted_5)], 40, _hoisted_4);
      }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list1, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.id,
          onMouseenter: pauseMoving,
          onMouseout: playMoving,
          onClick: $event => goDetail(item)
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item.thumb)
        }, null, 8, _hoisted_7)], 40, _hoisted_6);
      }), 128))])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list2, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.id,
          onMouseenter: pauseMoving,
          onMouseout: playMoving,
          onClick: $event => goDetail(item)
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item.thumb)
        }, null, 8, _hoisted_12)], 40, _hoisted_11);
      }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list2, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.id,
          onMouseenter: pauseMoving,
          onMouseout: playMoving,
          onClick: $event => goDetail(item)
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item.thumb)
        }, null, 8, _hoisted_14)], 40, _hoisted_13);
      }), 128))])])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list3, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.id,
          onMouseenter: pauseMoving,
          onMouseout: playMoving,
          onClick: $event => goDetail(item)
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item.thumb)
        }, null, 8, _hoisted_19)], 40, _hoisted_18);
      }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list3, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.id,
          onMouseenter: pauseMoving,
          onMouseout: playMoving,
          onClick: $event => goDetail(item)
        }, [_createElementVNode("img", {
          src: _unref(thumb)(item.thumb)
        }, null, 8, _hoisted_21)], 40, _hoisted_20);
      }), 128))])])])], 6);
    };
  }
};