/*
 * @Description: 接口列表
 * @Author: FreeDoM_BaT
 * @Date: 2022-11-17 22:54:15
 * @LastEditTime: 2023-01-20 10:24:26
 */
export const Apis = {
  cases: {
    banner: "/cases/banner",
    list: "/cases/list",
    detail: "/cases/detail",
  },
  jobs: {
    list: "/jobs/list",
    detail: "/jobs/detail",
  },
  form: {
    list: "/form/list",
    detail: "/form/detail",
    add: "/form/add",
  },
};

export const ApisProxy = "/api";
export const CdnProxy = "https://res.panstone.group/uploads/"; // 需要带/
