import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dd29a846"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
import { onMounted, onBeforeUnmount, ref } from "vue";
export default {
  __name: 'SectionHeader',
  props: {
    headerpic: {
      type: String,
      default: "/assets/images/title_contact_us.png"
    }
  },
  setup(__props) {
    const props = __props;
    function getWindowHeight() {
      let windowHeight = 0;
      if (document.compatMode === "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }
    const headerRoot = ref(null);
    let windowsHeight = getWindowHeight();
    const headerTransform = ref("transform: translateX(-50%) translateY(-66%);");
    function scrollHeader() {
      let headerOffsetTop = headerRoot.value.getBoundingClientRect().top;
      // 顶部和屏幕的距离
      const distOffset = headerOffsetTop - windowsHeight;
      const startOffset = windowsHeight * 5 / 5;
      if (distOffset < 0 || Math.abs(distOffset) > Math.abs(startOffset)) {
        // console.log("进入屏幕");
        let percent = 66;
        percent = 160 - Math.floor(Math.abs(distOffset) / startOffset * 100);
        console.log(percent);
        headerTransform.value = "transform: translateX(-50%) translateY(-" + percent + "%);";
      } else if (distOffset < -startOffset) {
        // console.log("离开屏幕");
        headerTransform.value = "transform: translateX(-50%) translateY(0%) translate3d(0px, 0px, 0px);";
      } else {
        // console.log("屏幕之外");
        headerTransform.value = "transform: translateX(-50%) translateY(-66%) translate3d(0px, 0px, 0px);";
      }
    }
    onMounted(() => {
      window.addEventListener("scroll", scrollHeader);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", scrollHeader);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "headerWord",
        ref_key: "headerRoot",
        ref: headerRoot,
        style: _normalizeStyle(headerTransform.value)
      }, [_renderSlot(_ctx.$slots, "default", {}, () => [_createElementVNode("img", {
        src: props.headerpic
      }, null, 8, _hoisted_1)])], 4);
    };
  }
};