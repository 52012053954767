/*
 * @Description:
 * @Author: FreeDoM_BaT
 * @Date: 2022-10-25 08:23:13
 * @LastEditTime: 2023-01-20 01:15:05
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import NProgress from "nprogress";
import "./assets/styles/common.scss";

router.beforeEach((to, from, next) => {
  NProgress.start();
  document.title =
    typeof to.meta.title == "undefined" ? "磐石集团官网" : to.meta.title;
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

router.afterEach(() => {
  NProgress.done();
});

createApp(App).use(router).mount("#app");
