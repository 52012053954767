/*
 * @Description: 封装配置和AXIOS
 * @Author: FreeDoM_BaT
 * @Date: 2022-11-17 22:55:37
 * @LastEditTime: 2022-12-15 13:36:16
 */
import Axios from "axios";
import { ApisProxy } from "./Apis";

const axios = Axios.create({
  // baseURL: "http://api.panstone.com",
  baseURL: ApisProxy,
});

export default axios;
