import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d5f886ec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "thumb"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "caption"
};
const _hoisted_4 = {
  class: "brand"
};
const _hoisted_5 = {
  class: "title"
};
import { onBeforeMount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import anime from "animejs";
import { thumb } from "@/utils/index";
export default {
  __name: 'CaseInfo',
  props: {
    case: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const props = __props;
    const caseItem = ref(null);
    const caseItemThumb = ref(null);
    const caseItemBrand = ref(null);
    const caseItemTitle = ref(null);
    const tl = ref(null);
    const isShow = ref(false);
    const $ = window.$;
    function addAnimate() {
      let headerOffsetTop = $(caseItem.value).offset().top;
      let wh = $(window).height();
      if (headerOffsetTop < wh * 0.75) {
        if (tl.value != null && isShow.value == false) tl.value.play();
      }
    }
    onMounted(() => {
      tl.value = anime.timeline({
        easing: "linear",
        duration: 500,
        delay: props.index % 3 * 300 + 100,
        loop: 1,
        autoplay: false,
        complete: function () {
          isShow.value = true;
        }
      });
      // tl.add({
      //   targets: caseItemThumb.value,
      //   translateY: [40, 0],
      // });
      tl.value.add({
        targets: caseItemBrand.value,
        translateY: ["100%", 0]
      });
      tl.value.add({
        targets: caseItemTitle.value,
        translateY: ["100%", 0]
      }, 100);
      addAnimate();
      window.addEventListener("scroll", addAnimate);
    });
    onBeforeMount(() => {
      window.removeEventListener("scroll", addAnimate);
    });
    const router = useRouter();
    function toDetail(item) {
      router.push({
        name: "CaseDetail",
        params: {
          id: item.id
        }
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "caseInfo",
        ref_key: "caseItem",
        ref: caseItem,
        onClick: _cache[0] || (_cache[0] = $event => toDetail(props.case))
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        ref_key: "caseItemThumb",
        ref: caseItemThumb,
        src: _unref(thumb)(props.case.thumb)
      }, null, 8, _hoisted_2)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", {
        ref_key: "caseItemBrand",
        ref: caseItemBrand
      }, _toDisplayString(props.case.brand), 513)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
        ref_key: "caseItemTitle",
        ref: caseItemTitle
      }, _toDisplayString(props.case.title), 513)])])], 512);
    };
  }
};