/*
 * @Description:
 * @Author: FreeDoM_BaT
 * @Date: 2022-11-17 22:52:33
 * @LastEditTime: 2022-12-15 22:12:19
 */

import axios from "./Config";
import { Apis } from "./Apis";

export function getList(data) {
  return new Promise((resolve, reject) => {
    let params = {
      page: data.page || 1,
      pagesize: 6,
    };
    if (data.type) params.type = data.type;
    axios
      .get(Apis.cases.list, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getDetail(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(Apis.cases.detail, {
        params: {
          id: id || 0,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
