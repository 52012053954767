/*
 * @Description:
 * @Author: FreeDoM_BaT
 * @Date: 2022-11-17 22:52:33
 * @LastEditTime: 2023-01-10 22:04:26
 */

import axios from "./Config";
import { Apis } from "./Apis";

export function getBanner() {
  return new Promise((resolve, reject) => {
    axios
      .get(Apis.cases.banner)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function postForm(data) {
  return new Promise((resolve, reject) => {
    let params = {
      name: data.name,
      corp: data.corp,
      mobile: data.mobile,
      type: data.type,
      content: data.content,
    };
    axios
      .post(Apis.form.add, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getDetail(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(Apis.cases.detail, {
        params: {
          id: id || 0,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
