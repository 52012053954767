//引入组件
const path = require("path");
const files = require.context("./", false, /.vue$/);
const modules = {};
files.keys().forEach((key) => {
  const name = "icon-" + path.basename(key, ".vue");
  modules[name] = files(key).default || files(key);
  console.log(name);
});

//挂载组件
export default {
  components: {
    ...modules,
  },
};
