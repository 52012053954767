/*
 * @Description:
 * @Author: FreeDoM_BaT
 * @Date: 2022-12-10 17:19:45
 * @LastEditTime: 2023-04-20 20:31:44
 */

import { CdnProxy } from "../api/Apis";

/**
 * 获取缩略图地址
 * @param String thumburl
 * @returns
 */
export function thumb(thumburl) {
  var isUrl = thumburl.search(/^http(s)?:\/\//i);
  if (isUrl >= 0) {
    return thumburl;
  } else {
    return CdnProxy + thumburl;
  }
}

const chkMobile = () => {
  //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
  var info = navigator.userAgent;
  //通过正则表达式的test方法判断是否包含“Mobile”字符串
  var isPhone = /mobile/i.test(info);
  //如果包含“Mobile”（是手机设备）则返回true
  return isPhone;
};

export const isMobile = chkMobile();
