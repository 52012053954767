/*
 * @Description:
 * @Author: FreeDoM_BaT
 * @Date: 2022-10-25 08:23:13
 * @LastEditTime: 2023-01-20 01:16:12
 */
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
// import BasicLayout from "../layouts/BasicLayouts.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/case",
    name: "Case",
    meta: {
      title: "案例 - 磐石集团",
    },
    component: () => import("../views/case/Index.vue"),
  },
  {
    path: "/detail/:id",
    name: "CaseDetail",
    component: () => import("../views/case/Detail.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "关于我们 - 磐石集团",
    },
    component: () => import("../views/about/Index.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "联系我们 - 磐石集团",
    },
    component: () => import("../views/contact/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
