import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f487a95c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "main"
};
import slogan from "@/components/home/Slogan.vue";
import bannerSwiper from "@/components/home/BannerSwiper.vue";
import caseList from "@/components/case/CaseList.vue";
export default {
  __name: 'Home',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(bannerSwiper), _createElementVNode("div", _hoisted_2, [_createVNode(slogan), _createVNode(caseList, {
        maxpage: 3
      })])]);
    };
  }
};